import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/Home/Home/Home";
import Navigationbar from "./components/Navigationbar/Navigationbar";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import NotImplementedPage from "./components/NotImplementedPage/NotImplementedPage";
import Prices from "./components/Prices/Prices";
import SOSCarTow from "./components/Services/SOSCarTow/SOSCarTow";
import Referencies from "./components/Referencies/Referencies";
import About from "./components/About/About";
import Impressum from "./components/Impresszum/Impressum";
import JumpStart from "./components/Services/JumpStart/JumpStart";
import Puncture from "./components/Services/Puncture/Puncture";
import OtherServices from "./components/Services/OtherServices/OtherServices";
import Repair from "./components/Services/Repair/Repair";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <>
      <Navigationbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/automentes" element={<SOSCarTow />} />
        <Route path="/bikazas" element={<JumpStart />} />
        <Route path="/defekt" element={<Puncture />} />
        <Route path="/szerviz" element={<Repair />} />
        <Route path="/szallitasi-szolgaltatasok" element={<OtherServices />} />
        <Route path="/referenciak" element={<Referencies />} />
        <Route path="/rolunk" element={<About />} />
        <Route path="/arak" element={<Prices />} />
        <Route path="/kapcsolat" element={<Contact />} />
        <Route path="/impresszum" element={<Impressum />} />
        <Route path="*" element={<NotImplementedPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
