import React from "react";
import "./homeHero.css";
import Carousel from "react-bootstrap/Carousel";
import { CONTACT } from "../../../contactInfo";


const HomeHero = () => {
  
  return (
    <div className="navbar-margin-top hero-container">
      <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block hero-img"
            src="/assets/images/home/homehero/hero1.webp"
            alt="Országos lefedettségű autómentés azonnal Budapesten munkafelvételtől számított 30 percen belül"
          />
          <Carousel.Caption className="hero-img-text-box">
            <h3 className="hero-img-title">0-24 autómentés azonnal</h3>
            <p className="hero-img-description">
              Országos lefedettséggel vállalunk autómentést.
              <br />
              Budapesten munkafelvételtől számított 30 percen belül ott vagyunk!
            </p>
            <a
              href={`tel:${CONTACT.PHONELINK}`}
              className="hero-img-button regular-button btn"
            >
              Hívás most!
            </a>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block hero-img"
            src="/assets/images/home/homehero/garage.webp"
            alt="Független autószerviz Budapesten, legjobb szakemberekkel"
            id="secondHomePic"
          />
          <Carousel.Caption className="hero-img-text-box">
            <h3 className="hero-img-title">Független autószerviz</h3>
            <p className="hero-img-description">
              Budapesttől 10 percre található szervizünkben a legjobb kezekben
              tudhatja járművét
            </p>
            <a
              href={`tel:${CONTACT.PHONELINK}`}
              className="hero-img-button regular-button btn"
            >
              Hívás most!
            </a>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block hero-img"
            src="/assets/images/home/homehero/import-cars.webp"
            id="lastHomePic"
            alt="Tervezhető járműszállítás, import-export"
          />
          <Carousel.Caption className="hero-img-text-box">
            <h3 className="hero-img-title">
              Tervezhető járműszállítás
            </h3>
            <p className="hero-img-description">
              Vállaljuk egyedi járművek szállítását Magyarországon és Európán belül is
            </p>
            <a
              href={`tel:${CONTACT.PHONELINK}`}
              className="hero-img-button regular-button btn"
            >
              Hívás most!
            </a>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default HomeHero;
