export const otherServicesImages = [
  {
    id: 1,
    src: "/assets/images/referencies/kep25.webp",
    alt: "Teljeskörű járműszállítás Magyarország területén kedvező áron",
  },
  {
    id: 2,
    src: "/assets/images/referencies/kep1.webp",
    alt: "Teljeskörű járműszállítás Magyarország területén kedvező áron",
  },
  {
    id: 3,
    src: "/assets/images/referencies/kep16.webp",
    alt: "Teljeskörű járműszállítás Magyarország területén kedvező áron",
  },
  {
    id: 4,
    src: "/assets/images/referencies/kep17.webp",
    alt: "Teljeskörű járműszállítás Magyarország területén kedvező áron",
  },
];
