import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useSwipeable } from "react-swipeable";
import "./referencies.css";
import { referenciesItems } from "./referenciesItems";
import Banner from "../Banner/Banner";

const Referencies = () => {
  const [data, setData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    setData({ img: img, i: i });
  };
  const imgAction = (action) => {
    let i = data.i;
    if (action === "next") {
      setData({ img: referenciesItems[i + 1], i: i + 1 });
    } else if (action === "prev") {
      setData({ img: referenciesItems[i - 1], i: i - 1 });
    } else {
      setData({ img: "", i: 0 });
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => imgAction("next"),
    onSwipedRight: () => imgAction("prev"),
  });

  return (
    <>
      <Banner content="Referenciák" />
      {data.img && (
        <div
          style={{
            position: "fixed",
            top: "3rem",
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "rgba(0,0,0,0.8)",
            zIndex: 1000,
          }}
        >
          <button
            className="close-button"
            onClick={() => imgAction()}
          >
            X
          </button>
          <button className="left-button" onClick={() => imgAction("prev")}>
            &larr;
          </button>
          <img
            {...handlers}
            src={data.img}
            style={{ width: "auto", maxWidth: "60%", maxHeight: "80%" }}
            alt="Autómentés, bikázás, különleges szállítás"
          />
          <button onClick={() => imgAction("next")} className="right-button">
            &rarr;
          </button>
        </div>
      )}
      <div className="container referencies-container">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="1rem">
            {referenciesItems.map((img, i) => (
              <img
                key={i}
                src={img}
                alt="Autómentés, bikázás, különleges szállítás"
                className="referencies-img"
                style={{ width: "100%", display: "block", cursor: "pointer" }}
                onClick={() => viewImage(img, i)}
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
};

export default Referencies;
