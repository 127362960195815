import React, { useEffect } from "react";
import "./jumpStart.css";
import Banner from "../../Banner/Banner";
import ServiceCTA from "../ServiceCTA/ServiceCTA";
import ServiceImagesDisplay from "../ServiceImagesDisplay/ServiceImagesDisplay";
import { jumpStartImages } from "./jumpStartImages";

const JumpStart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner content="Bikázás" id="#top" />
      <div className="container jump-start-container">
        <div className="jump-start-intro">
          <p className="jump-start-intro-text">
            Sajnos sokszor előfordul, hogy égve hagyott fényszórók,
            csomagtérvilágítás, vagy egyéb okok miatt lemerül az akkumulátor. Ilyen esetekben sajnos nem indul az autó, és nem is lehet elindítani. Ilyenkor a legjobb megoldás a bikázás. 
          </p>
          <p className="jump-start-intro-text">
            Ne hagyja, hogy egy lemerült akkumulátor állítsa meg! Hívjon minket
            bátran, és mi gondoskodunk arról, hogy autója újra zökkenőmentesen
            induljon!
          </p>
        </div>
        <ServiceCTA title="Bikázásra" />
        <ServiceImagesDisplay images={jumpStartImages} />
      </div>
    </>
  );
};

export default JumpStart;
