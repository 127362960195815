export const referenciesItems = [
  "/assets/images/referencies/kep1.webp",
  "/assets/images/referencies/kep2.webp",
  "/assets/images/referencies/kep3.webp",
  "/assets/images/referencies/kep4.webp",
  "/assets/images/referencies/kep5.webp",
  "/assets/images/referencies/kep6.webp",
  "/assets/images/referencies/kep8.webp",
  "/assets/images/referencies/kep9.webp",
  "/assets/images/referencies/kep10.webp",
  "/assets/images/referencies/kep11.webp",
  "/assets/images/referencies/kep12.webp",
  "/assets/images/referencies/kep13.webp",
  "/assets/images/referencies/kep14.webp",
  "/assets/images/referencies/kep15.webp",
  "/assets/images/referencies/kep16.webp",
  "/assets/images/referencies/kep17.webp",
  "/assets/images/referencies/kep18.webp",
  "/assets/images/referencies/kep19.webp",
  "/assets/images/referencies/kep20.webp",
  "/assets/images/referencies/kep21.webp",
  "/assets/images/referencies/kep22.webp",
  "/assets/images/referencies/kep23.webp",
  "/assets/images/referencies/kep24.webp",
  "/assets/images/referencies/kep25.webp",
  "/assets/images/referencies/kep26.webp",
  "/assets/images/referencies/kep27.webp",
  "/assets/images/referencies/kep28.webp",
  "/assets/images/referencies/kep29.webp",
  "/assets/images/referencies/kep30.webp",
];
