import React from "react";
import "./homeTestimonials.css";
import { Link } from "react-router-dom";

const HomeTestimonials = () => {
  return (
    <div className="home-testimonials-container">
      <div className="home-testimonials-wrapper">
        <div className="home-testimonials-text-wrapper">
          <h2 className="home-testimonials-title">Vélemények ügyfeleinktől</h2>
          <h4 className="home-testimonials-subtitle">Minden vélemény számít</h4>
          <div className="home-testimonials-text-box">
            <div className="home-testimonials-text-box-people">
              <div className="home-testimonials-text-card">
                <p className="home-testimonials-text-card-content">
                  Sajnos az én autómat már többször is kellett szállítani, de
                  Gábor mindig gyorsan és precízen segített. Köszönöm!
                </p>
                <p className="home-testimonials-text-card-name">
                  {" "}
                  - Németh András
                </p>
              </div>
              <div className="home-testimonials-text-card">
                <p className="home-testimonials-text-card-content">
                  Az úriember kicsit határozott de segítőkész. Miközben
                  dolgozott az autómmal, beültetett a meleg autómentőbe.
                </p>
                <p className="home-testimonials-text-card-name"> - Kiss Ria</p>
              </div>
              <div className="home-testimonials-text-card">
                <p className="home-testimonials-text-card-content">
                  Kereket cseréltünk volna, de valamiért ledőlt az autó az
                  emelőről a féktárcsára. Kollégájuk, jött és pár perc alatt
                  valahogy lábra tette. Ügyes fickó, látszik, hogy ért hozzá.
                </p>
                <p className="home-testimonials-text-card-name">
                  {" "}
                  - Bonyhádi Tibor
                </p>
              </div>
              <div className="home-testimonials-text-card">
                <p className="home-testimonials-text-card-content">
                A kolléga határozott megjelenése ellenére barátságos, kedves hozzáállást tapasztaltam a gyors munkavégzés mellett. Meg is lepődtem hogy a kerékcsere nem tartott 3 percig sem. Abszolút elégedett vagyok, csak ajánlani tudom.
                </p>
                <p className="home-testimonials-text-card-name"> - Lapath Erzsébet</p>
              </div>
            </div>
            <div className="home-testimonials-text-box-referencies-cta">
              <p className="home-testimonials-text-box-referencies-cta-text">
                REFERENCIÁK
              </p>
              <Link to="/referenciak" className="regular-button home-testimonials-text-box-referencies-cta-button btn">
              
                Megnézem &rarr;
              </Link>
            </div>
          </div>
        </div>
        <div className="home-testimonials-gallery">
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep3.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep8.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep9.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep10.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep11.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep12.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep18.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep17.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep22.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep26.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep23.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
          <figure className="home-testimonials-gallery-item">
            <img
              src="assets/images/referencies/kep24.webp"
              alt="autómentés budapesten, kedvező áron éjjel-nappal"
              className="home-testimonials-img"
            />
          </figure>
        </div>
      </div>
    </div>
  );
};

export default HomeTestimonials;
