import React from "react";
import "./impressum.css";
import Banner from "../Banner/Banner";
import { CONTACT } from "../../contactInfo";
import { impressumItems } from "./impressumItems";

const Impressum = () => {
  return (
    <>
      <Banner content="Impresszum" />
      <div className="container impressum-container">
        <h2 className="impressum-title">FETISH CAR Kft.</h2>
        <div className="impressum-div">
          <h4 className="impressum-subtitle">Cégadatok:</h4>
          <div className="impressum-text-item">
            <p className="impressum-text-title">
              <strong>Cégjegyzékszám</strong>:
            </p>
            <p className="impressum-text-description"> 01-09-286298</p>
          </div>
          <div className="impressum-text-item">
            <p className="impressum-text-title">
              <strong>Adószám</strong>:
            </p>
            <p className="impressum-text-description"> 25734351-2-41</p>
          </div>
          <div className="impressum-text-item">
            <p className="impressum-text-title">
              <strong>Székhely/Telephely</strong>:
            </p>
            <p className="impressum-text-description">
              {" "}
              1037 Budapest, Bojtár utca 36.
            </p>
          </div>
          <div className="impressum-text-item">
            <p className="impressum-text-title">
              <strong>Telefonszám</strong>:
            </p>
            <p className="impressum-text-description">{CONTACT.PHONE}</p>
          </div>
          <div className="impressum-text-item">
            <p className="impressum-text-title">
              <strong>E-mail cím</strong>:
            </p>
            <p className="impressum-text-description">{CONTACT.EMAIL}</p>
          </div>
          <div className="impressum-text-item">
            <p className="impressum-text-title">
              <strong>Honlap</strong>:
            </p>
            <p className="impressum-text-description">https://soskincsem.hu</p>
          </div>
        </div>
        <div className="impressum-div">
          <h4 className="impressum-subtitle">Tárhelyszolgáltató adatai:</h4>
          {impressumItems.map((item) => (
            <>
              {item.company_name && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>Név</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_name}
                  </p>
                </div>
              )}
              {item.company_based && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>Székhely</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_based}
                  </p>
                </div>
              )}
              {item.company_letter_address && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>Levelezési cím</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_letter_address}
                  </p>
                </div>
              )}
              {item.company_company_registration_number && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>Cégjegyzékszám</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_company_registration_number}
                  </p>
                </div>
              )}
              {item.company_tax_number && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>Adószám</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_tax_number}
                  </p>
                </div>
              )}
              {item.company_email && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>E-mail</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_email}
                  </p>
                </div>
              )}
              {item.company_phone && (
                <div className="impressum-text-item">
                  <p className="impressum-text-title">
                    <strong>Telefon</strong>:
                  </p>
                  <p className="impressum-text-description">
                    {item.company_phone}
                  </p>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default Impressum;
