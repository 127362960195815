import React from "react";
import "./otherServices.css";
import Banner from "../../Banner/Banner";
import ServiceImagesDisplay from "../ServiceImagesDisplay/ServiceImagesDisplay";
import { otherServicesImages } from "./otherServicesImages";
import { CONTACT } from "../../../contactInfo";

const OtherServices = () => {
  return (
    <>
      <Banner content="Egyéb szállítási szolgáltatásaink" />
      <div className="container other-services-container">
        <div className="other-services-intro">
          <p className="other-services-intro-text">
            Az SOSKincsemnél nem csak autómentéssel és autószállítással
            foglalkozunk, hanem számos egyéb szállítási szolgáltatást is
            kínálunk.
          </p>
          <p className="other-services-ul-text">
            Ezen járművek szállítását is vállaljuk. Ezeket a szolgáltatásokat az
            ország teljes területén, illetve külföldre is vállaljuk.
          </p>
          <ul className="other-services-ul-grid">
            <li> Motorkerékpárok</li>
            <li> Quadok</li>
            <li> Kis teherautók</li>
            <li> Munkagépek</li>
            <li> Versenyautók</li>
            <li> Veteránautók</li>
          </ul>
        </div>
        <div className="other-services-CTA">
          <p className="other-services-CTA-text">
            Ha Önnek szállítási szolgáltatásra lenne szüksége, és szeretne
            teljes körű kiszolgálást, hívjon minket bizalommal!
          </p>
          <a href={`tel:${CONTACT.PHONELINK}`} className="regular-button btn">
            Hívjon most
          </a>
        </div>
        <ServiceImagesDisplay images={otherServicesImages} />
      </div>
    </>
  );
};

export default OtherServices;
