import React from "react";
import "./serviceCTA.css";
import { CONTACT } from "../../../contactInfo";

const ServiceCTA = ({title}) => {
  return ( 
    <div className="sos-car-service-CTA">
    <h3 className="sos-car-service-CTA-title">{title} van szüksége? </h3>
    <p className="sos-car-service-CTA-subtitle">
      Éjszaka, hétvégén és ünnepnapokon is hívhat minket!
    </p>
    <p className="sos-car-service-CTA-subtitle">
      Budapesten belül munkafelvételtől számítva 30 perc alatt ott vagyunk!
    </p>
    <a
      href={`tel:${CONTACT.PHONELINK}`}
      className="regular-button btn"
    >
      Telefonálok most
    </a>
  </div>
   );
}
 
export default ServiceCTA;