import { CONTACT } from "../../../contactInfo";

export const sosCarTowItems = [
  {
    id: 1,
    content: `Baleset érte, vagy meghibásodott az autója? Hívjon minket a ${CONTACT.PHONE}-as telefonszámon!`,
  },
  {
    id: 2,
    content:
      "A diszpécserrel történő egyeztetés alapján megadjuk az árat és a várható érkezési időt.",
  },
  {
    id: 3,
    content:
      "A megbeszélt időpontban megérkezünk és elszállítjuk az autóját szervizünkbe vagy az ön által megadott helyszínre.",
  },
  {
    id: 4,
    content:
      "Igény esetén kérhető taxiszolgáltatásunk keretein belül elvisszük önt kívánt célpontjára",
  },
];
