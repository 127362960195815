import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./repair.css";
import Banner from "../../Banner/Banner";
import { CONTACT } from "../../../contactInfo";

const Repair = () => {
  const [showForm, setShowForm] = useState(false);

  const showFormHandler = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Banner content="Autószerviz" />
      <div className="container repair-container">
        <div className="repair-intro">
          <p className="repair-intro-text">
            Autómentés mellett vállalunk szervizelést is, melynek keretein belül
            minden típusú autót javítunk. Így mentés után a szükséges szervizt
            mi is el tudjuk végezni.
          </p>
          <p className="repair-intro-text">
            Természetesen nem csak autómentést követően vállalunk szervizelési
            munkákat, hanem a tervezhető/kötelező szervizekkel is szeretettel
            várjuk önöket.
          </p>
        </div>
        <div className="repair-services-container">
          <p className="repair-services-text">
            <strong>
              {" "}
              Szervizünkben a következő szolgáltatásokat tudjuk biztosítani:
            </strong>
          </p>
          <ul className="repair-services-list">
            <li className="repair-services-list-item">
              <img
                src="assets/images/services/diagnostic.png"
                alt="Autódiagnosztika és javítás Budapesten"
                className="repair-services-list-item-icon"
              />
              <p className="repair-services-list-item-text">
              <span style={{display: 'inline-block', width: '200px'}}>Diagnosztika és javítás</span>
              </p>
            </li>
            <li className="repair-services-list-item">
              <img
                src="assets/images/services/oil-indicator.png"
                alt="Autókarbantartás és szerviz, olajcsere Budapesten"
                className="repair-services-list-item-icon"
              />
              <p className="repair-services-list-item-text">
                Karbantartás
              </p>
            </li>
            <li className="repair-services-list-item">
              <img
                src="assets/images/services/brake.png"
                alt="Autódiagnosztika és javítás Budapesten"
                className="repair-services-list-item-icon"
              />
              <p className="repair-services-list-item-text" >
              <span style={{display: 'inline-block', width: '200px'}}>Fékek és felfüggesztés</span>
              </p>
            </li>
            <li className="repair-services-list-item">
              <img
                src="assets/images/services/accumulator.png"
                alt="Autódiagnosztika és javítás Budapesten"
                className="repair-services-list-item-icon"
              />
              <p className="repair-services-list-item-text">
              <span style={{display: 'inline-block', width: '200px'}}>Elektronikai hibák javítása</span>
              </p>
            </li>
            <li className="repair-services-list-item">
              <img
                src="assets/images/services/maintenance.png"
                alt="Futóműjavítás Budapesten"
                className="repair-services-list-item-icon"
              />
              <p className="repair-services-list-item-text">Futóműszerviz</p>
            </li>
            <li className="repair-services-list-item">
              <img
                src="assets/images/services/turbo.png"
                alt="Alkatrész értékesítés Budapesten"
                className="repair-services-list-item-icon"
              />
              <p className="repair-services-list-item-text">
              <span style={{display: 'inline-block', width: '200px'}}>Alkatrészek értékesítése</span>
              </p>
            </li>
          </ul>
          <p className="repair-services-text">
            Szervizünk látogatásához előzetes időpont egyeztetés szükséges.
          </p>
          <p className="repair-services-text">
            <strong>Címünk: 2011 Budakalász, Budai út 97. </strong>
          </p>
        </div>
        <div className="repair-cta-container">
          <a
            href={`tel:${CONTACT.PHONELINK}`}
            className="regular-button repair-cta-button btn"
          >
            Telefonálok
          </a>
          <a
            href={`mailto:${CONTACT.EMAIL}`}
            className="regular-button repair-cta-button btn"
          >
            Email-t írok
          </a>
          <button
            className="contact-us-step-button regular-button btn show-form-btn repair-cta-button"
            href="/"
            onClick={showFormHandler}
          >
            ŰRLAP KITÖLTÉSE
          </button>
        </div>
        <div className="contact-us-step contact-us-step-form-container repair-form-container">
          {showForm && (
            <div className="contact-us-step-form-wrapper">
              <form action="https://formbold.com/s/3LrrX" method="POST">
                <h4 className="contact-us-step-form-title">
                  Kapcsolatfelvételi űrlap
                </h4>
                <p className="contact-us-step-form-description">
                  Kérjük, töltse ki az alábbi mezőket, és mi 24 órán belül
                  válaszolunk megkeresésére!
                </p>
                <input
                  type="text"
                  placeholder="Név"
                  name="name"
                  className="contact-form-input"
                  required
                ></input>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="contact-form-input"
                  required
                ></input>
                <input
                  type="tel"
                  placeholder="Telefonszám"
                  name="phone"
                  className="contact-form-input"
                  required
                ></input>
                <textarea
                  name="message"
                  placeholder="Írjon nekünk üzenetet"
                  className="contact-form-textarea"
                  required
                ></textarea>
                <div className="contact-form-checkbox-wrapper">
                  <input
                    type="checkbox"
                    name="checkbox"
                    className="contact-form-checkbox"
                    required
                  ></input>
                  <span className="contact-form-checkbox-text">
                    Elolvastam és elfogadom az{" "}
                    <Link to="/adatvedelmi-tajekoztato">
                      adatvédelmi tájékoztatót
                    </Link>
                    .
                  </span>
                </div>
                <div className="contact-form-button-wrapper">
                  <button
                    type="close"
                    className="regular-button btn show-form-btn contact-form-close-btn"
                    onClick={showFormHandler}
                  >
                    Bezárás
                  </button>
                  <button
                    type="submit"
                    className="regular-button btn show-form-btn contact-form-submit-btn"
                  >
                    Küldés
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Repair;
