import React from "react";
import "./serviceImagesDisplay.css";

const ServiceImagesDisplay = ({ images }) => {
  return (
    <div className="sid-container">
      <h3 className="sid-title">Galéria</h3>
      <div className="sid-grid">
        <div className="sid-image-grid">
          {images.map((image) => (
            <div className="sid-image-container" key={image.id}>
              <img className="sid-image" src={image.src} alt={image.alt} />
            </div>
          ))}
        </div>
        <div className="sid-text-grid">
          <p className="sid-text">
            Ammennyiben szeretne további képeket látni szolgáltatásainkról,
            kérjük látogassa meg a referencia oldalunkat, amely ide kattintva
            érhető el.
          </p>
          <a
            href="/referenciak"
            className="sid-button regular-button btn"
          >
            &rarr;
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServiceImagesDisplay;
