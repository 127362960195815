import React, { useEffect } from "react";
import "./sosCarTow.css";
import Banner from "../../Banner/Banner";
import { sosCarTowItems } from "./sosCarTowItems";
import ServiceCTA from "../ServiceCTA/ServiceCTA";
import { CONTACT } from "../../../contactInfo";

const SOSCarTow = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Banner content="Autómentés 0-24" id="top" />
      <div className="container sos-car-tow-container">
        <div className="sos-car-tow-intro-container">
          <div className="sos-car-tow-intro">
            <p className="sos-car-tow-intro-text">
              Autómentés szolgáltatásunkat azért hoztuk létre, hogy a balesetet
              szenvedett vagy a műszaki hiba miatt megállt autósoknak segítsünk.
            </p>
            <p className="sos-car-tow-intro-text">
              Sajnos sokszor előfordul, hogy nem csak baleset, hanem műszaki
              hiba miatt nem indul az autó. Ilyenkor sincs semmi ok aggodalomra,
              hiszen segítünk. Hívja a {CONTACT.PHONE} telefonszámot, és mi a
              lehető legrövidebb időn belül ott leszünk.
            </p>
            <p className="sos-car-tow-intro-text">
              Szolgáltatásunk 0-24 órában elérhetőek, így bármikor hívhat
              minket, ha bajba kerül.
            </p>
          </div>
          <div className="sos-car-tow-intro-img-wrapper">
            <img
              src="assets/images/referencies/kep6.webp"
              alt="Recovery Car"
              className="sos-car-tow-intro-img"
            />
            <p className="sos-car-tow-intro-img-text">
              Ezt a járművet használjuk a mentési szolgáltatásaink során.
            </p>
          </div>
        </div>
        <ServiceCTA title="Autómentésre" />

        <div className="sos-car-tow-hiw-container">
          <h3 className="sos-car-tow-hiw-title">Hogyan működik?</h3>
          <div className="sos-car-tow-hiw-grid">
            {sosCarTowItems.map((item, index) => (
              <div className="sos-car-tow-hiw-text" key={item.id}>
                <p className="sos-car-tow-hiw-step">{item.id}</p>
                <p className="sos-car-tow-hiw-text-content">{item.content}</p>
              </div>
            ))}
          </div>
          <a
            href={`tel:${CONTACT.PHONELINK}`}
            className="sos-car-tow-hiw-button regular-button btn"
          >
            Telefonálok most
          </a>
        </div>
      </div>
    </>
  );
};

export default SOSCarTow;
