import React from "react";
import "./footer.css";
import logo from "../../assets/images/logo/soskincsem_logo_w_text_vertical.png";
import facebookLogo from "../../assets/images/footer/facebook.png";
import { CONTACT } from "../../contactInfo";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="outer-div">
    <footer className="footer-container">
      <div className=" footer-grid grid grid--3-cols">
        <div className="logo-col">
          <a href="/" className="footer-logo">
            <img src={logo} alt="soskincsem logo" className="footer-logo-img" />
          </a>
          <ul className="social-links">
            <li>
              <a
                href="https://www.facebook.com"
                className="footer-link"
                aria-label="SOSKincsem facebook oldal"
              >
                <img
                  src={facebookLogo}
                  className="facebook-logo"
                  alt="facebook logó"
                />
              </a>
            </li>
          </ul>
        </div>
        <div className="address-col">
          <p className="footer-heading">Nyitvatartás</p>
          <address className="contacts">
            <div className="contact-div">
              <p className="footer-address-title">Autószerviz és iroda </p>
              <p className="footer-address-text">2011 Budakalász, Budai út 97.</p>
              <p className="footer-address-text">H-P: 9 - 17 óráig</p>
              <p className="footer-address-text">SZ-V: zárva</p>
              <p className="footer-address-title">Autómentés</p>
              <p className="footer-address-text">
                {" "}
                0-24, éjjel-nappal hívható!
              </p>
              <a href={`tel:{CONTACT.PHONE}`} className="footer-address-text">
                {CONTACT.PHONE}
              </a>
            </div>
          </address>
        </div>
        <div className="contact-col">
          <p className="footer-heading">Vegye fel velünk a kapcsolatot</p>
          <div className="footer-contact-form-wrapper">
            <form action="https://formbold.com/s/9kYA2" method="POST">
              <input
                type="text"
                placeholder="Név"
                name="name"
                className="footer-contact-from-input"
                required
              ></input>
              <input
                type="email"
                placeholder="Email"
                name="email"
                className="footer-contact-from-input"
                required
              ></input>
              <input
                type="tel"
                placeholder="Telefonszám"
                name="phone"
                className="footer-contact-from-input"
                required
              ></input>
              <textarea
                name="message"
                placeholder="Írjon nekünk üzenetet"
                className="footer-contact-form-textarea"
                required
              ></textarea>
              <div className="contact-form-checkbox-wrapper">
                <input
                  type="checkbox"
                  name="checkbox"
                  className="contact-form-checkbox"
                  required
                ></input>
                <span className="contact-form-checkbox-text">
                  Elolvastam, megértettem és elfogadom az{" "}
                  <a
                    href="/assets/soskincsem_adatkezelesi_tajekoztato.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    adatvédelmi tájékoztatót
                  </a>
                  .
                </span>
              </div>
              <button type="submit" className="footer-contact-form-submit-btn">
                Küldés
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="docs-row">
        <ul className="footer-docs-links">
          <li>
            <a
              className="footer-link"
              href="/assets/soskincsem_adatkezelesi_tajekoztato.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Adatvédelmi tájékoztató
            </a>
          </li>
          <li>
            <a className="footer-link" href="/impresszum">
              Impresszum
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright-row">
        <p className="copyright-text">
          &copy; {year} by SOSKincsem. Minden jog fenntartva.{" "}
          {/* </p>
        <p className="copyright-text">
          {" "} */}
          A weboldalon található ikonok a{" "}
          <a href="https://www.flaticon.com" target="_blank" rel="noreferrer">
            Flaticon
          </a>{" "}
          oldalról származnak.
        </p>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
