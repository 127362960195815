import React from "react";
import "./banner.css";

const Banner = ({content}) => {
  return ( 
    <div className="banner-container">
      <div className="banner-wrapper">
        <div className="banner-text-wrapper">
          <h1 className="banner-text">{content}</h1>
        </div>
      </div>
    </div>
   );
}
 
export default Banner;