import React from "react";
import "./about.css";
import Banner from "../Banner/Banner";

const About = () => {
  return (
    <>
      <Banner content="Rólunk" />
      <div className="container about-container">
        <div className="about-intro">
          <p className="about-intro-text">
            Üdvözöljük a SOSKincsem világában, ahol autós problémáira gyors és
            hatékony megoldást kínálunk!{" "}
          </p>
          <p className="about-intro-text">
            1996 óta foglalkozom autómentéssel és autószereléssel.
            Szolgáltatásaink között szerepel bikázás, kerékcsere, továbbá minden típusú jármű szállítása,
            legyen az akár versenyautó vagy munkagép, nem okoz gondot. Mindezt
            az ország teljes területén, illetve külföldre is.
          </p>
        </div>

        <div className="about-worker-grid">
          <div className="about-worker-pic">
            <img
              src="/assets/images/referencies/kep5.webp"
              alt="Gábor autómentő és autószerelő"
            />
          </div>
          <div className="about-worker-text">
            <h3 className="about-worker-text-title">Bánkuti Gábor</h3>
            <p className="about-worker-text-content">
              Autómentő és autószerelő, aki már több mint 20 éve dolgozik a
              szakmában. Minden típusú járművet szállít, és szervizel. A
              munkáját precízen, gyorsan és megbízhatóan végzi.
            </p>
            <p className="about-worker-text-content-quote">
              "Hívj bátran, legyen szó autómentésről, autószállításról,
              bikázásról, vagy akár autójavításról. Az SOSKincsem nem csak
              szolgáltatás, hanem egy baráti kéznyújtás azoknak, akiknek
              szükségük van rá." <br />- Gábor
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
