import React from "react";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import "./notImplementedPage.css";
import animationData from "../../assets/lottie/404/Animation - 1701851864677.json";

const notImplementedPage = () => {
  return (
    <div className="not-implemented-page-container navbar-margin-top">
      <div className="not-implemented-page-grid">
   
        <div className="not-implemented-page-grid-text">
          <h1 className="not-implemented-page-header">
            Hoppá!
          </h1>
          <h1 className="not-implemented-page-header">
            Ez az oldal fejlesztés alatt áll vagy nem található!
          </h1>
          <Link to="/" className="not-implemented-page-link regular-button btn">
            Vissza a főoldalra
          </Link>
        </div><div className="not-implemented-page-grid-animation">
          <Lottie
            animationData={animationData}
            className="not-implemented-page-lottie"
          />
        </div>
      </div>     
    </div>
  );
};

export default notImplementedPage;
