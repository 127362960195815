import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./navigationbar.css";
import logo from "../../assets/images/logo/soskincsem_logo.png";
import { CONTACT } from "../../contactInfo";
import Lottie from "lottie-react";
import animationData from "../../assets/lottie/navbar/phone_animation.json";

const Navigationbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const HamburgerIcon = (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 12h18M3 6h18M3 18h18" stroke="#fff" strokeWidth="2" />
    </svg>
  );

  const CloseIcon = (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 18L18 6M6 6l12 12"
        stroke="#fff"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );

  const toggleIcon = isOpen ? CloseIcon : HamburgerIcon;

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="navbar-container"
      bg="myBlue"
      fixed="top"
      expanded={isOpen}
      onToggle={(expanded) => setIsOpen(expanded)}
    >
      <Navbar.Brand href="/" className="navbar-brand">
        <img
          alt=""
          src={logo}
          width="40"
          height="40"
          className="d-inline-block align-top"
        />{" "}
        SOSKINCSEM
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className={`navbar-toggle ${isOpen ? "open" : ""}`}
      >
        {toggleIcon}
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link href="/automentes" className="navbar-link">
            Autómentés 0-24
          </Nav.Link>
          <NavDropdown
            title={
              <span className="navbar-dropdown-text">Szolgáltatásaink</span>
            }
          >
            <NavDropdown.Item href="/automentes">
              Autómentés 0-24
            </NavDropdown.Item>
            <NavDropdown.Item href="/bikazas">Bikázás</NavDropdown.Item>
            <NavDropdown.Item href="/defekt">
              Defekt kezelése
            </NavDropdown.Item>
            
            <NavDropdown.Item href="/felretankolas">Félretankolás</NavDropdown.Item><NavDropdown.Item href="/szerviz">Autószerviz</NavDropdown.Item>
            <NavDropdown.Item href="/szallitasi-szolgaltatasok">
              Egyéb szállítási szolgáltatások
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/rolunk" className="navbar-link">
            Rólunk
          </Nav.Link>
          <Nav.Link href="/referenciak" className="navbar-link">
            Referenciák
          </Nav.Link>
          <Nav.Link href="/arak" className="navbar-link">
            Árak
          </Nav.Link>
          <Nav.Link href="/kapcsolat" className="navbar-link">
            Kapcsolat
          </Nav.Link>
          <Nav.Link href={`tel:${CONTACT.PHONELINK}`}className="navbar-link navbar-call-btn">
            Telefonálok
            <span>
              <Lottie
                animationData={animationData}
                className="navbar-lottie-animation"
              />
            </span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigationbar;
