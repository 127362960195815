import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./contact.css";
import "../Home/HomeServices/homeServices.css";
import Banner from "../Banner/Banner";
import { CONTACT } from "../../contactInfo";
import locationIcon from "../../assets/images/contact/location.png";
import phoneIcon from "../../assets/images/contact/phone.png";
import emailIcon from "../../assets/images/contact/email.png";
import officeIcon from "../../assets/images/contact/office-building.png";
import towtruckIcon from "../../assets/images/contact/tow-truck.png";
import googleTerkep from "../../assets/images/contact/googleterkep.png";
import callUsStep from "../../assets/images/contact/soskincsem-phone.png";
import emailUsStep from "../../assets/images/contact/soskincsem-email.png";
import FreqAskQuestions from "./FreqAskQuestions/FreqAskQuestions.jsx";

const Contact = () => {
  const [showForm, setShowForm] = useState(false);

  const showFormHandler = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      <Banner content="Kapcsolat" />
      <div className="container contact-us-container">
        <div className="contact-us-steps-wrapper">
          <div className="contact-us-step">
            <div className="contact-us-step-img-box">
              <img
                src={callUsStep}
                alt="SOSkincsem hívása"
                className="animate"
              />
            </div>
            <div className="contact-us-step-text-box">
              <h3 className="contact-us-step-title">
                Azonnali mentésre van szüksége?
              </h3>
              <p className="contact-us-step-description">
                Budapesti indulással országos lefedettséggel mentünk, hívjon
                minket a {CONTACT.PHONE} telefonszámon és azonnal indulunk!
              </p>
              <a
                href={`tel:${CONTACT.PHONELINK}`}
                className="contact-us-step-button regular-button btn"
              >
                Telefonálok
              </a>
            </div>
          </div>
          <div className="contact-us-step">
            <div className="contact-us-step-img-box">
              <img
                src={emailUsStep}
                alt="SOSkincsem hívása"
                className="animate"
              />
            </div>
            <div className="contact-us-step-text-box">
              <h3 className="contact-us-step-title">
                Tervezhető szállításra van szüksége? Szeretne árajánlatot kérni?
                <br />
              </h3>
              <p className="contact-us-step-description">
                Kérjük, írjon nekünk az {CONTACT.EMAIL} email címre, vagy töltse
                ki kapcsolatfelvételi űrlapunkat és mi 24 órán belül
                válaszolunk!
              </p>
              <div className="contact-form-button-wrapper">
                <a
                  href={`mailto:${CONTACT.EMAIL}`}
                  className="contact-us-step-button regular-button btn"
                >
                  Emailt írok
                </a>
                <button
                  className="contact-us-step-button regular-button btn show-form-btn"
                  href="/"
                  onClick={showFormHandler}
                >
                  ŰRLAP KITÖLTÉSE
                </button>
              </div>
            </div>
            <div className="contact-us-step contact-us-step-form-container">
              {showForm && (
                <div className="contact-us-step-form-wrapper">
                  <form action="https://formbold.com/s/9kYA2" method="POST">
                    <h4 className="contact-us-step-form-title">
                      Kapcsolatfelvételi űrlap
                    </h4>
                    <p className="contact-us-step-form-description">
                      Kérjük, töltse ki az alábbi mezőket, és mi 24 órán belül
                      válaszolunk megkeresésére!
                    </p>
                    <input
                      type="text"
                      placeholder="Név"
                      name="name"
                      className="contact-form-input"
                      required
                    ></input>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="contact-form-input"
                      required
                    ></input>
                    <input
                      type="tel"
                      placeholder="Telefonszám"
                      name="phone"
                      className="contact-form-input"
                      required
                    ></input>
                    <textarea
                      name="message"
                      placeholder="Írjon nekünk üzenetet"
                      className="contact-form-textarea"
                      required
                    ></textarea>
                    <div className="contact-form-checkbox-wrapper">
                      <input
                        type="checkbox"
                        name="checkbox"
                        className="contact-form-checkbox"
                        required
                      ></input>
                      <span className="contact-form-checkbox-text">
                        Elolvastam, megértettem és elfogadom az{" "}
                        <a
                          href="/assets/soskincsem_adatkezelesi_tajekoztato.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          adatvédelmi tájékoztatót
                        </a>
                        .
                      </span>
                    </div>
                    <div className="contact-form-button-wrapper">
                      <button
                        type="close"
                        className="regular-button btn show-form-btn contact-form-close-btn"
                        onClick={showFormHandler}
                      >
                        Bezárás
                      </button>
                      <button
                        type="submit"
                        className="regular-button btn show-form-btn contact-form-submit-btn"
                      >
                        Küldés
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="contact-us-info-wrapper">
          <div className="contact-us-info-grid">
            <div className="contact-us-info-box-address">
              <p className="contact-us-info-box-title">Elérhetőségeink</p>
              <div className="contact-us-info-box-text-item">
                <img
                  src={locationIcon}
                  alt="cím ikon"
                  className="contact-us-info-box-text-icon"
                />
                <p className="contact-us-info-box-text">
                  2011 Budakalász, Budai út 97.
                </p>
              </div>
              <div className="contact-us-info-box-text-item">
                <img src={phoneIcon} alt="telefon ikon" />
                <p className="contact-us-info-box-text">{CONTACT.PHONE}</p>
              </div>
              <div className="contact-us-info-box-text-item">
                <img src={emailIcon} alt="email ikon" />
                <p className="contact-us-info-box-text">{CONTACT.EMAIL}</p>
              </div>
            </div>
            <div className="contact-us-info-box-openhours">
              <p className="contact-us-info-box-title">Nyitvatartás</p>
              <div className="contact-us-info-box-text-item">
                <img src={officeIcon} alt="iroda ikon" />
                <p className="contact-us-info-box-text-title">Iroda</p>
                <p className="contact-us-info-box-text">
                  Hétfő - Péntek: 9 - 17 óráig
                </p>
                <p className="contact-us-info-box-text"></p>
                <p className="contact-us-info-box-text">
                  Szombat - Vasárnap: Zárva
                </p>
              </div>
              <div className="contact-us-info-box-text-item">
                <img src={towtruckIcon} alt="iroda ikon" />
                <p className="contact-us-info-box-text-title">Autómentés</p>
                <p className="contact-us-info-box-text">
                  0-24 hívható, éjjel-nappal
                </p>
                <p className="contact-us-info-box-text">
                  HÉTVÉGÉN és ÜNNEPNAPOKON IS!
                </p>
              </div>
            </div>
            <div className="contact-us-info-box-map">
              <div className="contact-us-info-box-map-img">
                <Link
                  to="https://maps.app.goo.gl/kCAXJxNxERkYLnHh6"
                  target="_blank"
                >
                  <img
                    src={googleTerkep}
                    alt="telephely térkép linkkel"
                    rel="preload"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-us-form-wrapper"></div>
      </div>
      <FreqAskQuestions />
    </>
  );
};

export default Contact;
