import React from "react";
import Marquee from "react-fast-marquee";
import "./homePartners.css";
/* ------ LOGO IMPORTS ------ */
import logo1 from "../../../assets/images/home/homepartners/logos/autodokilogo.png";
import logo2 from "../../../assets/images/home/homepartners/logos/autopalace-buda.svg";
import logo3 from "../../../assets/images/home/homepartners/logos/dunaauto.svg";
import logo4 from "../../../assets/images/home/homepartners/logos/emil-frey-gruppe-logo-vector.png";
import logo5 from "../../../assets/images/home/homepartners/logos/europ-assistance-logo.png";
import logo6 from "../../../assets/images/home/homepartners/logos/la-fer-car-peugeot-citroen-szerviz-logo.png";
import logo7 from "../../../assets/images/home/homepartners/logos/magyar-autoklub.png";
import logo8 from "../../../assets/images/home/homepartners/logos/mercarius.png";
import logo9 from "../../../assets/images/home/homepartners/logos/schiller-logo.png";

const homePartners = () => {
  return ( 
    <div className="container home-partners-container">
      <h2 className="home-partners-title">Korábbi munkáink</h2>
    <Marquee pauseOnHover>
      <img src={logo1} alt="autódoki logó" className="home-partners-logo" />
      <img
        src={logo2}
        alt="autópalace buda logó"
        className="home-partners-logo" />
      <img src={logo3} alt="duna auto logó" className="home-partners-logo" />
      <img
        src={logo4}
        alt="emil frey group logó"
        className="home-partners-logo" />
      <img src={logo5} alt="europ assistance logó" className="home-partners-logo" />
      <img
        src={logo6}
        alt="la fer car logó"
        className="home-partners-logo" />
      <img src={logo7} alt="magyar autóklub logó" className="home-partners-logo" />
      <img src={logo8} alt="mercarius logó" className="home-partners-logo" />
      <img src={logo9} alt="schiller logó" className="home-partners-logo" />
    </Marquee>
    </div>
   );
}
 
export default homePartners;