import HomeHero from "../HomeHero/HomeHero";
import HomeServices from "../HomeServices/HomeServices";
import HomePartners from "../HomePartners/HomePartners";
import "./home.css";
import HomeTestimonials from "../HomeTestimonials/HomeTestimonials";

const Home = () => {
  return (
    <>
      <HomeHero />
      <HomeServices />
      <HomeTestimonials />
      <HomePartners />
    </>
  );
};

export default Home;
