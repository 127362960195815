import React from "react";
import Table from "react-bootstrap/Table";
import "./prices.css";
import Banner from "../Banner/Banner";

const Prices = () => {
  return (
    <>
      <Banner content="Áraink" />
      <div className="prices-text-container">
        <p className="prices-text">
          Kérjük tekintse meg a szolgáltatásainkra vonatkozó általános árlistánkat.
        </p>
        <p className="prices-text">
          Minden autómentés egyedi és az árak autómentésenként eltérőek
          lehetnek. Indulás előtt a kapott információk alapján megadjuk a
          szállítás teljes költségét, így elkerülhetőek lesznek rejtett
          költségek.
        </p>
        <p className="prices-text">
          Az árak a szállítandó jármű típusától, súlyától, méretétől, a
          szállítás távolságától, időpontjától, módjától, helyszínétől, a
          szállítás során felmerülő egyéb költségektől függően változhatnak.
        </p>
        <p className="prices-text">
          <strong>
            Az árak tájékoztató jellegűek és az áfát nem tartalmazzák.
          </strong>
        </p>
      </div>
      <div className="prices-container">
        <Table striped bordered hover className="prices-table">
          <thead>
            <tr>
              <th>Tételek</th>
              <th>1100 kg - ig</th>
              <th>1101 - 3500 kg</th>
              <th>3501 - 5000 kg</th>
              <th>5001 kg - tól</th>
            </tr>
          </thead>
          <tbody>
            <tr className="odd-row">
              <td className="table-first-column">Alapdíj (Ft)</td>
              <td>15.000</td>
              <td>20.000</td>
              <td>30.000</td>
              <td>95.500</td>
            </tr>
            <tr>
              <td className="table-first-column">
                Várakozási díj (Ft/30 perc)
              </td>
              <td>8.000</td>
              <td>8.000</td>
              <td>Egyedi</td>
              <td>Egyedi</td>
            </tr>
            <tr className="odd-row">
              <td className="table-first-column">Km díj (Ft/km)</td>
              <td>500</td>
              <td>500</td>
              <td>500</td>
              <td>700</td>
            </tr>
            <tr>
              <td className="table-first-column">
                Átalánydíjas szállítás (Ft), csak Budapest területére nappali
                szállítás esetén
              </td>
              <td>20.000</td>
              <td>25.000</td>
              <td>45.000</td>
              <td>95.500</td>
            </tr>
            <tr>
              <td className="table-first-column">
                Tárolás fedetlen tárolóban (Ft/minden megkezdett nap)
              </td>
              <td>5.000</td>
              <td>5.000</td>
              <td>Egyedi</td>
              <td>Egyedi</td>
            </tr>
            <tr>
              <td className="table-first-column">
                Bikázás (Ft)
              </td>
              <td>15.000</td>
              <td>15.000</td>
              <td>15.000</td>
              <td>15.000</td>
            </tr>
            <tr>
              <td className="table-first-column">
                Defekt - kiszállás és pótkerék felrakása (Ft)
              </td>
              <td>20.000</td>
              <td>20.000</td>
              <td>20.000</td>
              <td>20.000</td>
            </tr>
          </tbody>
        </Table>
        <div className="prices-text-container">
          <p className="prices-text">
          <strong>
            Éjszaka, hétvégén és az ünnepnapokon történő autómentés esetén felárral kell számolni, melyet természetesen indulás előtt egyeztetünk a megrendelővel.
          </strong>
          </p>
        </div>
      </div>
    </>
  );
};

export default Prices;
