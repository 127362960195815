export const punctureImages = [
  {
    id: 1,
    src: "/assets/images/referencies/kep29.webp",
    alt: "Defektjavítás, autószállítás Budapesten teljes területén, éjjel-nappal",
  },
  {
    id: 2,
    src: "/assets/images/referencies/kep30.webp",
    alt: "Defektjavítás, autószállítás Budapesten teljes területén, éjjel-nappal",
  },
  {
    id: 3,
    src: "/assets/images/referencies/kep28.webp",
    alt: "Defektjavítás, autószállítás Budapesten teljes területén, éjjel-nappal",
  },
  {
    id: 4,
    src: "/assets/images/referencies/kep27.webp",
    alt: "Defektjavítás, autószállítás Budapesten teljes területén, éjjel-nappal",
  },
  
];
