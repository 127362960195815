export const impressumItems = [
  {
    id: 1,
    company_name: "BlazeArts Kft.",
    company_based: "1096 Budapest, Thaly Kálmán utca 39.",
    company_letter_address: "6090 Kunszentmiklós, Szent Erzsébet tér 11.",
    company_company_registration_number: "01-09-389087",
    company_tax_number: "12539833-2-43",
    company_email: "info@forpsi.hu",
    company_phone: "(+36) 1 610 5506 ",
  },
  {
    id: 2,
    company_name: "Netlify Inc.",
    company_based: "610 22nd St #315, San Francisco",
    company_letter_address: "610 22nd St #315, San Francisco",
    company_email: "support@netlify.com",
  }
]