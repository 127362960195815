export const homeServicesItems = [
  {
    id: 1,
    title: "Autómentés 0-24",
    description: (
      <>
        <p>Bármikor, bárhol. <br />
        Non-stop autómentési szolgáltatásunk mindig rendelkezésre áll.</p>
      </>
    ),
    linkSrc: "/automentes",
    picSrc: "/assets/images/home/homeservice/tow-truck.png",
    picAlt: "Autómentés 0-24",
  },
  {
    id: 2,
    title: "Bikázás 0-24",
    description: "Lemerült akkumulátor miatt nem indul autója? Számíthat ránk. ",
    linkSrc: "/bikazas",
    picSrc: "/assets/images/home/homeservice/mechanic.png",
    picAlt: "Bikázás",
  },  {
    id: 3,
    title: "Autószerviz",
    description: "Márkafüggetlen szervizünkben szakszerűen, gyorsan és kedvező áron javítjuk autóját.",
    linkSrc: "/autoszerviz",
    picSrc: "/assets/images/home/homeservice/car.png",
    picAlt: "Autószerviz",
  },
  {
    id: 4,
    title: "Egyéb szolgáltatások",
    description: "Előre látható szállítást szeretne esetleg különleges rakománya lenne? Keressen minket, megoldjuk!",
    linkSrc: "/szallitasi-szolgaltatasok",
    picSrc: "/assets/images/home/homeservice/crane-truck.png",
    picAlt: "Egyéb szolgáltatások",
  },
];
