export const jumpStartImages = [{
  id: 1, 
  src: "/assets/images/referencies/kep26.webp",
  alt: "Bikázás, autó indítása Budapesten teljes területén, éjjel-nappal",
}, {
  id: 2, 
  src: "/assets/images/referencies/kep13.webp",
  alt: "Bikázás, autó indítása Budapesten teljes területén, éjjel-nappal",
}, 
{
  id: 3, 
  src: "/assets/images/referencies/kep14.webp",
  alt: "Bikázás, autó indítása Budapesten teljes területén, éjjel-nappal",
},
{
  id: 4, 
  src: "/assets/images/referencies/kep15.webp",
  alt: "Bikázás, autó indítása Budapesten teljes területén, éjjel-nappal",
},]