import React from "react";
import { Link } from "react-router-dom";
import "./homeServices.css";
import { homeServicesItems } from "./homeServicesItems";
import { CONTACT } from "../../../contactInfo";

const HomeServices = () => {
  return (
    <div className="container home-services-container margin-top-5">
      <h2 className="home-services-title">BÍZZA SZAKEMBERRE AUTÓJÁT</h2>
      <div className="home-services-wrapper">
        <div className="home-services-intro-container">
          <div className="home-services-intro-wrapper">
            <div className="home-services-intro-text-wrapper">
              <p className="home-services-intro-text">
                Teljes körű autó- és teherautó-mentési szolgáltatásokat kínálunk
                versenyképes áron. Az SOSKincsem egy családi vállalkozás,
                melyben a nap 0-24 órában állunk rendelkezésére.
              </p>
              <p className="home-services-intro-text">
                Az SOSKincsem minden szükséges engedéllyel és biztosítással
                rendelkezik a teljes védelméért. Garantáljuk szolgáltatásunk
                hatékonyságát és az Ön elégedettségét.
              </p>
            </div>
            <div className="home-services-intro-img-wrapper">
              <img
                src="assets/images/referencies/kep6.webp"
                alt="Recovery Car"
                className="home-services-intro-img"
              />
              <p className="home-services-intro-img-text">
                Ezt a járművet használjuk a mentési szolgáltatásaink során.
              </p>
            </div>
          </div>
          <div className="home-services-intro-cta-container">
            <p className="home-services-intro-text">
              Ne habozzon, hívjon minket még ma a személyre szabott
              kiszolgálásért!
            </p>
            <a
              href={`tel:${CONTACT.PHONELINK}`}
              className="regular-button home-services-intro-phone-button btn"
            >
              Telefonálok
            </a>
          </div>
        </div>
        <div className="home-services-grid margin-top-3">
          {homeServicesItems.map((item) => (
            <Link to={`${item.linkSrc}#top`} key={item.id}>
              <div className="home-services-box" key={item.id}>
                <div className="home-services-box-img-wrapper">
                  <img
                    src={item.picSrc}
                    className="home-services-box-img"
                    alt={item.picAlt}
                  />
                </div>
                <div className="home-services-box-body">
                  <div className="">
                    <h4 className="home-services-box-body-title">
                      {item.title}
                    </h4>
                    <div className="home-services-box-body-text">
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
