import React from "react";
import "./puncture.css";
import Banner from "../../Banner/Banner";
import ServiceCTA from "../ServiceCTA/ServiceCTA";
import { punctureImages } from "./punctureImages";
import ServiceImagesDisplay from "../ServiceImagesDisplay/ServiceImagesDisplay";

const Puncture = () => {
  return (
    <>
      <Banner content="Defekt kezelése" />
      <div className="container puncture-container">
        <div className="puncture-intro">
          <p className="puncture-intro-text">
            Defektet szenvedett? Ne hajtsa tovább az autót, mert az a felnit és a
            jármű futóművét is súlyosan károsíthatja. Amennyiben rendelkezik
            pótkerékkel vagy abronccsal, akkor azt fel tudjuk szerelni, és Ön
            tovább tudja használni az autóját, ha autójában nincs pótkerék,
            abban az esetben pedig autóját elszállítjuk a legközelebbi vagy Ön
            által választott gumiszervizbe, ahol megjavítják a defektet vagy
            kicserélik a kereket.
          </p>
        </div>
        <ServiceCTA title="Abroncs defektjavítására" />
        <ServiceImagesDisplay images={punctureImages} />
      </div>
    </>
  );
};

export default Puncture;
